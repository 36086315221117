import { useCallback } from "react";
import { DeepPartial, UseFormSetValue } from "react-hook-form";
import { useAtomValue } from "jotai";

import { SLACK_ISSUE_REPORT_ATOM } from "@sellernote/_shared/src/jotaiStates/slackIssueReport";
import { getObjectEntries } from "@sellernote/_shared/src/utils/common/object";

import { ReportIssueForm } from ".";

/**
 * 전역 state로 저장된 debuggingDate를 form에 적용
 */
export default function useSetDebuggingDataFromGlobalState({
  formValue,
  setFormValue,
}: {
  formValue: DeepPartial<ReportIssueForm["debuggingData"]> | undefined;
  setFormValue: UseFormSetValue<ReportIssueForm>;
}) {
  const debuggingData = useAtomValue(
    SLACK_ISSUE_REPORT_ATOM.DEBUGGING_DATA_ATOM
  );

  const setDebuggingDataFromGlobalState = useCallback(() => {
    getObjectEntries(formValue || {}).forEach(([key, value]) => {
      if (key === "pageUrl") {
        setFormValue("debuggingData.pageUrl", window.location.href, {
          shouldDirty: true,
        });
        return;
      }

      setFormValue(`debuggingData.${key}`, debuggingData?.[key] || "", {
        shouldDirty: true,
      });
    });
  }, [debuggingData, formValue, setFormValue]);

  return {
    setDebuggingDataFromGlobalState,
    debuggingDataFromGlobalState: debuggingData,
  };
}
