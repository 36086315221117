import AttachmentIcon from "@mui/icons-material/Attachment";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  colors,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  Paper,
  Stack,
} from "@mui/material";
export default function FileUploader({
  fileList,
  updateFileList,
  addImageFileFromClipboard,
}: {
  fileList: File[];
  updateFileList: (v: File[]) => void;
  addImageFileFromClipboard: () => Promise<void>;
}) {
  const handleFileAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFileList = Array.from(e.target.files || []);

    updateFileList([...fileList, ...newFileList]);
  };

  const handleFileRemove = (index: number) => {
    const newFileList = fileList.filter((_, i) => i !== index);
    updateFileList(newFileList);
  };

  return (
    <Stack>
      <InputLabel>
        파일 첨부 {fileList.length > 0 ? `(${fileList.length})` : ""}
      </InputLabel>

      <Paper variant="outlined" sx={{ padding: "10px" }}>
        <Stack gap={2}>
          <Stack direction="row" gap={1}>
            <Button
              component="label"
              size="small"
              htmlFor="slack-message-file-uploader"
              role={undefined}
              variant="contained"
              startIcon={<AttachmentIcon />}
            >
              파일 선택
              <input
                type="file"
                id="slack-message-file-uploader"
                multiple
                onChange={handleFileAdd}
                style={{
                  display: "none",
                }}
              />
            </Button>

            <Button
              component="label"
              size="small"
              role={undefined}
              variant="contained"
              startIcon={<ContentPasteGoIcon />}
              onClick={addImageFileFromClipboard}
            >
              클립보드 이미지
            </Button>
          </Stack>

          <ImageList
            cols={5}
            rowHeight={90}
            sx={{ maxHeight: "100px", overflow: "scroll" }}
          >
            {fileList.map((file, i) => {
              const isImage = file.type.startsWith("image/");

              const DeleteButton = (
                <IconButton
                  onClick={() => handleFileRemove(i)}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <DeleteIcon color="error" sx={{ fontSize: "20px" }} />
                </IconButton>
              );

              const url = URL.createObjectURL(file as unknown as Blob);

              const ITEM_HEIGHT = 90;

              return isImage ? (
                <ImageListItem key={url} sx={{ position: "relative" }}>
                  <img
                    src={url}
                    alt={file.name}
                    loading="lazy"
                    style={{ height: ITEM_HEIGHT }}
                  />

                  {DeleteButton}
                </ImageListItem>
              ) : (
                <Box
                  key={url}
                  sx={{
                    position: "relative",
                    backgroundColor: colors.grey[200],
                    borderRadius: "8px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    overflow: "hidden",
                    height: ITEM_HEIGHT,
                  }}
                >
                  {file.name}

                  {DeleteButton}
                </Box>
              );
            })}
          </ImageList>
        </Stack>
      </Paper>
    </Stack>
  );
}
