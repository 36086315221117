import { Control } from "react-hook-form";
import { Controller } from "react-hook-form";
import {
  Box,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";

import { ReportIssueForm } from ".";

export default function NoteInput({
  control,
  addImageFileFromClipboard,
}: {
  control: Control<ReportIssueForm>;
  addImageFileFromClipboard: () => Promise<void>;
}) {
  return (
    <Controller
      name="note"
      control={control}
      rules={{
        required: "필수 입력 사항입니다.",
        minLength: {
          value: 10,
          message: "최소 10자 이상 입력해주세요.",
        },
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Stack
            gap={1}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              },
            }}
          >
            <InputLabel>내용</InputLabel>

            <Box>
              <TextField
                sx={{ flex: 1 }}
                value={value}
                fullWidth
                size="small"
                rows={5}
                multiline
                onChange={onChange}
                onPaste={addImageFileFromClipboard}
                placeholder="이슈를 설명해주세요. (이미지를 붙여넣으면 업로드됩니다)"
              />

              {error && (
                <FormHelperText error>⚠️ {error.message}</FormHelperText>
              )}
            </Box>
          </Stack>
        );
      }}
    />
  );
}
