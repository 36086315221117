import { Control, Controller } from "react-hook-form";
import { InputLabel, MenuItem, Select, Stack } from "@mui/material";

import { ReportIssueForm, SlackReportIssueType } from ".";
import { SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD } from "./constants";

const options = Object.entries(SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD).map(
  ([issueType, label]) => ({
    value: issueType,
    label,
  })
);

export default function IssueTypeSelect({
  control,
}: {
  control: Control<ReportIssueForm>;
}) {
  return (
    <>
      <Controller
        name="issueType"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <Stack
              gap={1}
              flex={1}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                },
              }}
            >
              <InputLabel>이슈 유형</InputLabel>

              <Select<SlackReportIssueType | "">
                value={value || ""}
                size="small"
                onChange={(e) => {
                  onChange(e);
                }}
              >
                {options.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          );
        }}
      />
    </>
  );
}
