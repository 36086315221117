/**
 * 로딩 프로그레스 인디게이터
 */

import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        size={25}
        style={{
          position: "absolute",
          left: "50%",
          right: "50%",
          marginTop: "250px",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  );
};

export default Loading;
