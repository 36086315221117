import { useCallback } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { SEND_SLACK_MESSAGE_AS_EASY_TO_WORK_BOT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminCommon";
import {
  APP_ENV,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import useIntersectionObserver from "@sellernote/_shared/src/hooks/common/useIntersectionObserver";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared/src/jotaiStates/auth";
import { DebuggingDataForSlackIssueReport } from "@sellernote/_shared/src/jotaiStates/slackIssueReport/atoms";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import {
  SLACK_CHANNEL,
  SLACK_ID_RECORD,
} from "@sellernote/_shared/src/services/slack";

import { normalizeFile } from "./utils";
import { formatSlackMessage } from "./utils";

import { TRIGGER_RIGHT_POSITION } from "..";
import DebuggingDataForm from "./DebuggingDataForm";
import FileUploader from "./FileUploader";
import IssuePrioritySelect from "./IssuePrioritySelect";
import IssueTypeSelect from "./IssueTypeSelect";
import NoteInput from "./NoteInput";
import Reset from "./Reset";
import useAutoApplyDebuggingData from "./useAutoApplyDebuggingData";

type ReportIssueForm = {
  issueType: SlackReportIssueType;
  note: string;
  priority: SlackReportPriorityType;
  debuggingData: DebuggingDataForSlackIssueReport;
  fileList?: {
    // File 타입을 사용하면 RHF에서 순환참조 오류가 나므로 최소 타입만 재정의해서 사용한다
    name: string;
    size: number;
    type: string;
    lastModified: number;
  }[];
};

type SlackReportIssueType = "updateData" | "error" | "feature" | "etc";

type SlackReportPriorityType = "low" | "medium" | "high" | "emergency";

export type { ReportIssueForm, SlackReportIssueType, SlackReportPriorityType };

// Production에서만 지원-포워딩채널로 보낸다
const reportChannel: SLACK_CHANNEL = IS_UNDER_PRODUCTION
  ? "지원-포워딩"
  : "test-일하기쉽다-둥지";

export default function Form({ hideForm }: { hideForm: () => void }) {
  const {
    watch,
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, touchedFields },
  } = useForm<ReportIssueForm>({
    mode: "onSubmit",
    defaultValues: {
      issueType: "updateData",
      priority: undefined,
      note: "",
      debuggingData: {
        pageUrl: "",
        bidId: "",
        userId: "",
        userCompany: "",
      },
      fileList: [],
    },
  });

  /**
   * form이 화면에 표시되었는지 확인한다.
   * - 화면에 표시되면 자동으로 debuggingData를 적용하기 위함
   */
  const { target, isActiveTarget } = useIntersectionObserver({
    threshold: 0.01,
  });

  const { issueType, note, debuggingData, priority } = watch();

  useAutoApplyDebuggingData({
    enabled: isActiveTarget,
    isDirtyForm: isDirty,
    setFormValue: setValue,
    formValue: debuggingData,
  });

  const { handleSnackbarOpen } = useSnackbar();

  const currentUser = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const {
    mutate: sendSlackMessage,
    ResponseHandler: ResponseHandlerOfSendSlackMessage,
  } = ADMIN_COMMON_QUERY.useSendSlackMessageAsEasyToWorkBot();

  const fileList = (watch("fileList") || []) as File[];

  /**
   * useFieldArray를 사용하지 않는 이유
   * - File객체처럼 깊은/복잡한 객체를 관리할 경우, append할때, 프로토타입 정보가 유실되는 이슈가 있음
   * - 프로토타입 뿐만아니라, 다른 정보들도 useFiledArray의 인터페이스에 맞지않으면 id값만 들어감
   * - workaround로 useForm의 setValue 등을 받아 사용하는식으로 우회 사용하는 방식으로 처리함.
   */
  const updateFileList = useCallback(
    (v: File[]) => {
      setValue("fileList", v, {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  /**
   * input에서 붙여넣기 한 이미지를 파일로 업로드
   */
  const addImageFileFromClipboard = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();

      for (const item of clipboardItems) {
        for (const type of item.types) {
          if (type.startsWith("image/")) {
            const blob = await item.getType(type);

            // "image/" 이후의 부분을 확장자로 추출
            const extension = type.substring(6);

            const newFile = new File(
              [blob],
              `screenshot-${Date.now()}.${extension}`,
              { type, lastModified: Date.now() }
            );

            updateFileList([...fileList, newFile]);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = () => {
    const reporterSlackInfo = SLACK_ID_RECORD[currentUser.email];
    if (!reporterSlackInfo?.id) {
      throw new Error("Slack ID가 없습니다.");
    }

    const message = formatSlackMessage({
      data: {
        issueType,
        priority,
        note,
        debuggingData: debuggingData || {},
        env: APP_ENV,
      },
      reporterSlackId: reporterSlackInfo.id,
      reportChannel,
    });

    if (!message) {
      handleSnackbarOpen(
        "Slack 메시지를 생성할 수 없습니다. 개발자에게 문의해주세요.",
        "error"
      );
      return;
    }

    const { initialMessage, detailMessageBlocks } = message;

    const formData = new FormData();

    for (const file of fileList || []) {
      const normalizedFile = normalizeFile(file as File);
      formData.append("files", normalizedFile);
    }

    formData.append("initialMessage", JSON.stringify(initialMessage));
    formData.append("detailMessageBlocks", JSON.stringify(detailMessageBlocks));

    // formData는 간단하게 key/value 타입을 정의할 수 없어 이렇게 처리
    sendSlackMessage(
      formData as unknown as SEND_SLACK_MESSAGE_AS_EASY_TO_WORK_BOT_REQ,
      {
        onSuccess: () => {
          handleSnackbarOpen(
            `'${reportChannel}'채널로 리포트되었습니다.`,
            "success"
          );
          reset();
          hideForm();
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        ref={target}
        elevation={10}
        sx={{
          gap: "18px",
          display: "flex",
          flexDirection: "column",
          width: "640px",
          maxHeight: "740px",
          overflow: "scroll",
          position: "relative",
          bottom: "10px",
          right: `${TRIGGER_RIGHT_POSITION}px`,
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px 16px",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">'지원-포워딩' 리포트</Typography>

          <IconButton onClick={hideForm}>
            <CloseIcon color="action" />
          </IconButton>
        </Stack>

        <Stack direction="row" gap={2}>
          <IssueTypeSelect control={control} />

          <IssuePrioritySelect control={control} />
        </Stack>

        <DebuggingDataForm control={control} setValue={setValue} />

        <NoteInput
          control={control}
          addImageFileFromClipboard={addImageFileFromClipboard}
        />

        <FileUploader
          updateFileList={updateFileList}
          fileList={fileList}
          addImageFileFromClipboard={addImageFileFromClipboard}
        />

        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          sx={{ marginTop: "20px" }}
        >
          <Reset reset={reset} />

          <Button type="submit" variant="contained" color="primary">
            제출
          </Button>
        </Stack>
      </Paper>

      {ResponseHandlerOfSendSlackMessage}
    </form>
  );
}
