import { SlackReportIssueType, SlackReportPriorityType } from ".";

const SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD: Record<
  SlackReportIssueType,
  string
> = {
  updateData: "✏️ 데이터 수정 요청",
  error: "🐞 오류 리포트",
  feature: "💡 기능 제안",
  etc: "💬 기타 이슈",
};

const SLACK_REPORT_PRIORITY_LABEL_RECORD: Record<
  SlackReportPriorityType,
  string
> = {
  emergency: "🚨 당장 처리 필요(긴급) 🚨",
  high: "🟠 가급적 빨리",
  medium: "🟡 오늘 내로만 처리 되면 됨",
  low: "🟢 여유있을때 해주세요",
};
export {
  SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD,
  SLACK_REPORT_PRIORITY_LABEL_RECORD,
};
