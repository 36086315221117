import { Control, Controller } from "react-hook-form";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import { ReportIssueForm, SlackReportPriorityType } from ".";
import { SLACK_REPORT_PRIORITY_LABEL_RECORD } from "./constants";

const options = Object.entries(SLACK_REPORT_PRIORITY_LABEL_RECORD).map(
  ([priority, label]) => ({
    value: priority,
    label,
  })
);

export default function IssuePrioritySelect({
  control,
}: {
  control: Control<ReportIssueForm>;
}) {
  return (
    <>
      <Controller
        name="priority"
        control={control}
        rules={{
          required: "필수 입력 사항입니다.",
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Stack
              gap={1}
              flex={1}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                },
              }}
            >
              <InputLabel>긴급도</InputLabel>

              <Box>
                <Select<SlackReportPriorityType | "">
                  value={value || ""}
                  size="small"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  fullWidth
                >
                  {options.map((option) => {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>

                {error && (
                  <FormHelperText error>⚠️ {error.message}</FormHelperText>
                )}
              </Box>
            </Stack>
          );
        }}
      />
    </>
  );
}
