import { useState } from "react";
import { UseFormReset } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import { ReportIssueForm } from ".";

export default function Reset({
  reset,
}: {
  reset: UseFormReset<ReportIssueForm>;
}) {
  const [showsConfirm, setShowsConfirm] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setShowsConfirm(true)}
      >
        초기화
      </Button>

      <Dialog open={showsConfirm} onClose={() => setShowsConfirm(false)}>
        <DialogTitle>정말 초기화하시겠습니까?</DialogTitle>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowsConfirm(false);
            }}
            variant="outlined"
          >
            아니오
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              reset();
              setShowsConfirm(false);
            }}
            variant="contained"
          >
            예
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
