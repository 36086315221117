import { ChatPostMessageArguments, KnownBlock } from "@slack/web-api";

import { SEND_SLACK_MESSAGE_AS_EASY_TO_WORK_BOT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminCommon";
import { AppEnv } from "@sellernote/_shared/src/constants";
import {
  SLACK_CHANNEL,
  SLACK_CHANNEL_ID_RECORD,
} from "@sellernote/_shared/src/services/slack";

import { ReportIssueForm } from ".";
import {
  SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD,
  SLACK_REPORT_PRIORITY_LABEL_RECORD,
} from "./constants";

type DataForReportIssue = {
  env: AppEnv;
} & Omit<ReportIssueForm, "fileList">;

/**
 * 이슈리포트에 필요한 데이터들을 모아 slack message 형식으로 포맷
 */
function formatSlackMessage({
  data,
  reporterSlackId,
  reportChannel,
}: {
  data: DataForReportIssue;
  reporterSlackId: string;
  reportChannel: SLACK_CHANNEL;
}):
  | Pick<
      SEND_SLACK_MESSAGE_AS_EASY_TO_WORK_BOT_REQ,
      "initialMessage" | "detailMessageBlocks"
    >
  | undefined {
  const basicInfo: string[] = [
    `*${SLACK_REPORT_PRIORITY_LABEL_RECORD[data.priority]}*`,
    `*환경*: ${data.env} | *요청인*: <@${reporterSlackId}>`,
  ];

  const debuggingInfo: { title: string; value: string | undefined }[] =
    Object.entries(data.debuggingData)
      .map(([key, value]) => {
        return {
          title: key,
          value: value,
        };
      })
      .filter(({ value }) => !!value);

  const initialMessage: ChatPostMessageArguments = {
    /** 보통의 환경에서는 실제로 표시되지않으나 fallback으로 넣는다 (ex. ARIA 같은 맥락) */
    text: `${SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD[data.issueType]}`,
    channel: SLACK_CHANNEL_ID_RECORD[reportChannel],
    blocks: [
      {
        type: "header",
        text: {
          type: "plain_text",
          text: `${SLACK_REPORT_ISSUE_TYPE_LABEL_RECORD[data.issueType]}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: basicInfo.reduce((a, c) => {
            return `${a}\n> ${c}`;
          }, ""),
        },
      },
    ],
  };

  const debuggingInfoSection: KnownBlock[] =
    debuggingInfo.length > 0
      ? [
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: debuggingInfo.reduce((a, c) => {
                return `${a}\n> *${c.title}*: ${c.value}`;
              }, ""),
            },
          },
        ]
      : [];

  const detailMessageBlocks: KnownBlock[] = [
    ...debuggingInfoSection,
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: data.note || "",
      },
    },
  ];

  return {
    initialMessage,
    detailMessageBlocks,
  };
}

/**
 * File객체 내부 구조를 통일함
 *
 * - 클립보드에 있는 스크린샷으로 생성한 File 객체와 input으로 업로드한 File객체간 차이때문에,
 * - 두 형식의 파일을 섞어서 axios로 보내면 Network오류가 생긴다 (아마 axios의 formData파싱시 처음 파싱하는 파일의 형식에 기반하여 다음 파일도 파싱하기 때문에 생기는 오류로 추정)
 * - 이를 해결하기 위해 Blob형태로 변환후 다시 File객체로 재변환하는 과정을 통해 File형식을 통일시킨다.
 */
function normalizeFile(file: File) {
  const blob = new Blob([file], { type: file.type });
  return new File([blob], file.name, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export { formatSlackMessage, normalizeFile };
